<template>
    <div class="page apartment fade-in">
        <div class="centered-content">
						<transition appear>
								<div v-if="product" class="apartment-modal-wrapper">
										<apartment-modal
												:apartment="product"
												:show-thumbnail="showThumbnail"
												:class="{'scrollable-y': isSideMenu}"
										></apartment-modal>
								</div>
						</transition>
            <transition appear>
                <content-modal
										v-if="apartmentContent" @close="closeContent"
										:class="{
												'full': apartmentContentType === 'gallery',
												'tabs': hasTabs,
												'inner-tabs': hasInnerTabs
										}"
								>
                    <template #header v-if="apartmentContent.tabs_layout === 'top_tabs'">
                        <span v-if="apartmentContent.label && apartmentContentType !== 'gallery'">{{apartmentContent.label}}</span>
                        <nav v-else-if="apartmentContent.tabs && apartmentContent.tabs.length > 1" class="header-tabs">
                          <ul>
                            <li v-for="(tab, tabIndex) in apartmentContent.tabs" :key="tabIndex">
                              <button type="button" :class="{active: currentContentTab === tabIndex}" @click="changeContentTab(tabIndex)">{{tab.label}}</button>
                            </li>
                          </ul>
                        </nav>
                    </template>
                    <building-specs v-if="apartmentContentType === 'specifications'" :content="apartmentContent"></building-specs>
                    <div class="gallery-slides" v-else-if="apartmentContentType === 'gallery'">
                        <div v-if="apartmentContentCurrentTab">
														<masonry-gallery
																v-if="apartmentContentCurrentTab.acf_fc_layout === 'gallery' && apartmentContentCurrentTab.type === 'masonry'"
																:images="apartmentContentCurrentTab.gallery"
																:items-per-row="isMobile ? apartmentContentCurrentTab.mobile_items_per_row : apartmentContentCurrentTab.items_per_row"
														></masonry-gallery>
                            <slider-gallery v-else-if="apartmentContentCurrentTab.acf_fc_layout === 'gallery'" :slides="apartmentContentCurrentTab.gallery">
                                <template v-slot="{slide}">
                                    <div
																				v-if="currentContentTabClickAction === 'edit' && !isMobile"
																				:class="`img-${slide.image_layout} image-edit`"
																				@click="$store.dispatch('openImageEditor', slide.url)"
																				tabindex="1"
																		>
                                        <img :src="slide.url" :alt="slide.alt">
																				<click-here></click-here>
                                    </div>
																		<div v-else-if="slide.type === 'image'" class="ps-gal" :class="[`type-${slide.type}`, `img-${slide.image_layout}`]">
																				<a
																						:href="slide.url"
																						:data-pswp-width="slide.width"
																						:data-pswp-height="slide.height"
																						target="_blank"
																						rel="noreferrer"
																				>
																						<img :src="slide.thumbnail" :alt="slide.alt">
																				</a>
																		</div>
																		<div v-else :class="[`type-${slide.type}`, `img-${slide.image_layout}`]">
																				<video :src="slide.url" controls></video>
																		</div>
                                </template>
                            </slider-gallery>
                            <div class="iframe" v-else-if="apartmentContentCurrentTab.acf_fc_layout === 'iframe'">
                                <iframe v-if="apartmentContentCurrentTab.src" :src="apartmentContentCurrentTab.src"></iframe>
																<illustration-only-disclaimer></illustration-only-disclaimer>
                            </div>
														<masonry-gallery
																v-else-if="apartmentContentCurrentTab.acf_fc_layout === 'masonry'"
																:images="apartmentContentCurrentTab.gallery"
																:items-per-row="isMobile ? apartmentContentCurrentTab.mobile_items_per_row : apartmentContentCurrentTab.items_per_row"
														></masonry-gallery>
                        </div>
                    </div>
                    <div class="flipbook-wrapper" v-else-if="apartmentContentType === 'flipbook'">
                      <fp-flip-book :pages="apartmentContent.images"></fp-flip-book>
                    </div>
										<template #footer>
												<nav
														v-if="apartmentContent.tabs_layout === 'bottom_buttons' && apartmentContent.tabs && apartmentContent.tabs.length > 1"
														class="footer-tabs"
												>
														<fp-button
																v-for="(tab, tabIndex) in apartmentContent.tabs"
																:key="tabIndex"
																:class="{active: currentContentTab === tabIndex}"
																@click="changeContentTab(tabIndex)"
														>{{tab.label}}</fp-button>
												</nav>
										</template>
                </content-modal>
            </transition>
        </div>
    </div>
</template>

<script>
import ApartmentModal from "@/components/ApartmentModal"
import {mapGetters, mapState} from "vuex"
import ContentModal from "@/components/ContentModal"
import BuildingSpecs from "@/components/BuildingSpecs"
import SliderGallery from "@/components/SliderGallery"
import FpFlipBook from "@/components/FpFlipBook"
import IllustrationOnlyDisclaimer from "@/components/IllustrationOnlyDisclaimer"
import MasonryGallery from "@/components/MasonryGallery"
import FpButton from "@/components/buttons/FpButton"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import ClickHere from "@/components/ClickHere.vue";

export default {
    name: "ApartmentPage",
    components: {
				ClickHere,
				FpButton,
				MasonryGallery,
				IllustrationOnlyDisclaimer,
				FpFlipBook, SliderGallery, BuildingSpecs, ContentModal, ApartmentModal},
    computed: {
				hasTabs(){
						return this.apartmentContent.tabs && this.apartmentContent.tabs.length > 1
				},
				hasInnerTabs(){
						return this.hasTabs && this.apartmentContentType === 'specifications'
				},
        product(){
            if(this.currentProductId && this.$store.state.products && this.$store.state.products[this.currentProductId]){
                return this.$store.state.products[this.currentProductId]
            }
            return null
        },
        apartmentContentType(){
            return this.$store.getters.apartmentContentType
        },
        apartmentContentCurrentTab(){
						if(this.apartmentContent && this.apartmentContent.tabs && this.apartmentContent.tabs[this.currentContentTab] && this.apartmentContent.tabs[this.currentContentTab].content){
								if(this.apartmentContent.tabs[this.currentContentTab].content[0]){
									return this.apartmentContent.tabs[this.currentContentTab].content[0]
								}
								return this.apartmentContent.tabs[0].content[0]
						}
						return null
        },
				showThumbnail(){
						return this.apartmentThumbnailDisplay && this.apartmentThumbnailDisplay.indexOf("apartment_page") > -1
				},
				currentContentTabClickAction(){
						if(this.apartmentContentCurrentTab?.click_action){
								return this.apartmentContentCurrentTab.click_action
						}
						return null
				},
        ...mapState(['currentProductId', 'currentProjectId']),
        ...mapGetters([
						'isMobile',
						'apartmentContent',
						'isSideMenu',
						'apartmentThumbnailDisplay',
						'hasWhatsappButton',
						'showFloatingContactButton',
						'bottomBarHeight',
						'mobileButtonsHeight',
						'products'
				])
    },
		watch:{
				apartmentContent(){
						this.changeContentTab(0)
				},
				products(){
						this.loadProduct(this.$route)
				}
		},
    data(){
      return {
        currentContentTab: 0,
				lightbox: null
      }
    },
    methods: {
        changeContentTab(tabIndex){
						this.currentContentTab = tabIndex

						this.destroyPs()
						this.$nextTick().then(() => {
								this.initPs()
						})
        },
        async loadProduct(to) {
            const id = to.params.apartmentId ? parseInt(`${to.params.apartmentId}`, 10) : null
            await this.$store.dispatch('loadProduct', id)
            this.openFirstContent()
        },
        closeContent(){
            this.$store.commit('apartmentContent', null)
        },
        openFirstContent(){
						if(!this.isMobile){
								if(!this.apartmentContent){
										if(this.product && this.product.buttons[0]){
												const firstContent = this.product.buttons[0]
												this.$store.commit('apartmentContent', firstContent)
										}else if(this.apartmentContent){
												this.$store.commit('apartmentContent', null)
										}
								}
						}
        },
        goToApartments(){
            this.$router.push({name: 'apartments', params: {id: this.currentProjectId}})
        },
				initPs(){
						if(this.currentContentTabClickAction !== 'edit' || this.isMobile){
								if (!this.lightbox) {
										this.lightbox = new PhotoSwipeLightbox({
												gallery: '.gallery-slides',
												children: '.ps-gal a',
												pswpModule: () => import('photoswipe'),
										});
										this.lightbox.init();
								}
						}
				},
				destroyPs(){
						if (this.lightbox) {
								this.lightbox.destroy();
								this.lightbox = null;
						}
				}
    },
		mounted() {
				this.destroyPs()
				this.$nextTick().then(() => {
						this.initPs()
				})
		},
		beforeRouteEnter(to, from, next) {
        next(async vm => {
            await vm.loadProduct(to)
        })
    },
		beforeRouteLeave(){
				this.closeContent()
		},
    async beforeRouteUpdate(to) {
        await this.loadProduct(to)
    },
}
</script>

<style scoped lang="scss">
.page.apartment {
    display: flex;
    align-items: flex-start;
    padding: 0 2.6875rem;

    .centered-content {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        flex-grow: 1;
        gap: 15px;
        max-width: calc(100vw - 2.6875rem * 2);
    }
}

.apartment-modal-wrapper {
		&.v-enter-active,
		&.v-leave-active {
				transition: transform .5s ease;
		}

		&.v-enter-from,
		&.v-leave-to {
				transform: translateX(500px);
		}
}

.apartment-modal {
    width: 311px;
    max-width: calc(100vw - 2rem);
    flex-shrink: 0;
    flex-grow: 0;
}

.content-modal {
    max-width: calc(100vw - 411px);

    &.v-enter-active,
    &.v-leave-active {
        transition: transform .5s ease,opacity .5s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateX(-100vw);
        opacity: 0;
    }

    :deep(.content-modal-header) {
        display: flex;
        width: 100%;
        &.tabs {
          background-color: #000;
          color: #fff;
        }

        nav.header-tabs {
            flex-grow: 1;

            ul {
                display: flex;
            }

            ul button {
                color: #fff;
                font-size: 18px;
                margin: 0 6px;

                &.active {
                  color: var(--primary-light);
                }
            }
        }

    }

		:deep(nav.footer-tabs) {
				display: flex;
				gap: 15px;
				margin-bottom: 15px;
				z-index: 10;
				overflow-x: auto;
				margin-right: 10px;
				margin-left: 10px;

				.btn {
						background-color: #000;
						&:first-child {
								margin-right: auto;
						}

						&:last-child {
								margin-left: auto;
						}
				}
		}
}

.is-mobile {
    .page.apartment {
        padding: 0;
				background-color: rgba(0,0,0,.86);

        .centered-content {
            flex-flow: column;
            gap: 0;
            max-width: none;
        }

        :deep(.apartment-modal) {
            width: 100vw;
            border: none;
            max-width: none;
            border-radius: 0;
            background-color: transparent;
            background-image: none;
						padding-bottom: 100px;

						.modal-content {
								align-items: center;
						}

						.apt-price {
								margin: 5px 1px;
						}
        }

				:deep(.content-modal) {
						&.tabs:not(.inner-tabs) {
								top: 100px;
						}
						.content-modal-header {
								padding-right: 15px;
								padding-left: 15px;
						}
				}

    }
}

.fp-side-menu-wrapper + .fp-app-wrapper {
		.page.apartment {
				padding: 0;
				align-items: stretch;

				:deep(.apartment-modal) {
						border: none;
						background-color: transparent;
						background-image: linear-gradient(to left, rgb(0 0 0 / 80%) 50%,rgb(0 0 0 / 90%) 100%);
						backdrop-filter: blur(5px);
						border-radius: 0;
						height: 100vh;
						flex-shrink: 0;
				}

				.centered-content {
						gap: 0;
						max-width: none;
						align-items: stretch;
						overflow: hidden;
						height: 100vh;
				}

				:deep(.content-modal){
						border: none;
						border-radius: 0;
						max-width: none;
						display: flex;
						flex-flow: column;
						align-items: stretch;

						.content-modal-content {
								flex-grow: 1;
						}

						.content-modal-header ~ .content-modal-content .iframe {
								height: calc(100vh - 50px);
						}
				}
		}
}

.ltr {

		.apartment-modal-wrapper {

				&.v-enter-from,
				&.v-leave-to {
						transform: translateX(-500px);
				}
		}

		.content-modal {
				&.v-enter-from,
				&.v-leave-to {
						transform: translateX(100vw);
				}

				:deep(nav.footer-tabs) {
						.btn {
								background-color: #000;
								&:first-child {
										margin-right: 10px;
										margin-left: auto;
								}

								&:last-child {
										margin-left: 10px;
										margin-right: auto;
								}
						}
				}
		}
}
</style>
