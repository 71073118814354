<template>
    <ul>
<!--        <li><fp-icon-button symbol="globe" symbol-size="23"><span v-if="showLabels">שפה</span></fp-icon-button></li>-->
        <li>
						<fp-icon-button
								animated="disabled"
								@click="toggleAccessibilityModal"
								symbol-size="32"
						>
							<span v-if="showLabels">{{$t('נגישות')}}</span>
						</fp-icon-button>
				</li>
        <li>
						<fp-icon-button
								:to="{name: 'terms'}"
								animated="error"
								symbol-size="32"
								:title="$t('תקנון')"
						>
								<span v-if="showLabels">{{ $t('תקנון') }}</span>
						</fp-icon-button>
				</li>
				<li>
						<fp-icon-button
								:to="{name: 'accessibility'}"
								animated="error"
								symbol-size="32"
								:title="$t('הצהרת נגישות')"
						>
								<span v-if="showLabels">{{ $t('הצהרת נגישות') }}</span>
						</fp-icon-button>
				</li>
        <li v-if="isMobile">
						<fp-icon-button
								v-if="showProjectsTab"
								@click="toggleProjectsPane"
								symbol="caret-right-circle"
						>{{$t('פרוייקטים')}}</fp-icon-button>
				</li>
    </ul>
</template>

<script>
import FpIconButton from "@/components/buttons/FpIconButton";
import {mapGetters} from "vuex";
export default {
    name: "MenuBarLeft",
    props: {
        showLabels: {
            default: false
        }
    },
    components: {FpIconButton},
    computed: {
        showProjectsTab(){
            if(this.$store.getters.settings && this.$store.getters.settings.projects && this.$store.getters.settings.projects.show_projects_tab !== undefined){
                return this.$store.getters.settings.projects.show_projects_tab
            }
            return true
        },
				termsPost(){
						if(this.settings && this.settings.global_posts && this.settings.global_posts[`terms_and_conditions_${this.locale}`]){
								return this.settings.global_posts[`terms_and_conditions_${this.locale}`]
						}
						return null
				},
				termsPostName(){
						if(this.termsPost && this.termsPost.post_name){
								return this.termsPost.post_name;
						}
						return this.locale === 'he' ? 'תקנון' : 'terms'
				},
        ...mapGetters(['isMobile','settings','locale'])
    },
    methods: {
        toggleProjectsPane(){
            this.$store.dispatch('toggleProjectsPane')
        },
				toggleAccessibilityModal(){
						this.$store.commit('toggleAccessibilityModal')
				}
    }
}
</script>

<style scoped>

</style>
